<template>
  <transition name="fade">
    <div
      v-if="scrollAt > 500"
      class="group fixed bottom-1/2 right-2 z-[1000] flex transform cursor-pointer items-center justify-between gap-3 rounded-lg bg-yellow-300 p-4 shadow-md transition-all hover:-translate-y-1 hover:scale-105 hover:shadow-xl 2xl:right-4"
      @click.stop="toTop"
    >
      <img
        src="~/assets/img/icons/arrow.svg"
        class="w-2 -rotate-90 transform transition-all duration-300 group-hover:-translate-y-2"
      />
    </div>
  </transition>
</template>

<script setup>
const scrollAt = ref('');

const setScrollAt = () => {
  scrollAt.value = window.scrollY;
};

onMounted(() => {
  window.addEventListener('scroll', setScrollAt);
});

onUnmounted(() => {
  window.removeEventListener('scroll', setScrollAt);
});

const toTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
